import { CHILD_TYPES } from 'src/app/components/filterContainers/NestedCheckboxFilterModal/constants';

export const sourcesList = [
  { name: 'ABN Newswire', label: 'ABN Newswire' },
  { name: 'Basic News Sources', label: 'Basic News Sources' },
  { name: 'ACCESSWIRE', label: 'ACCESSWIRE' },
  { name: 'Baystreet', label: 'Baystreet' },
  { name: 'ACCESSWIRE IA', label: 'ACCESSWIRE IA' },
  { name: 'PR Newswire', label: 'PR Newswire' },
  { name: 'Business Wire', label: 'Business Wire' },
  { name: 'ACN Newswire', label: 'ACN Newswire' },
  { name: 'The News Wire', label: 'The News Wire' },
  { name: 'United Press International', label: 'United Press International' },
  { name: 'Newsfile', label: 'Newsfile' },
  { name: 'Canada Newswire', label: 'Canada Newswire' },
  { name: 'GlobeNewswire', label: 'GlobeNewswire' },
  { name: 'News Direct', label: 'News Direct' },
  { name: 'Equities.com', label: 'Equities.com' },
  { name: 'Stock News Now', label: 'Stock News Now' },
  { name: 'Motley Fool', label: 'Motley Fool' },
  { name: 'Motley Fool Canada', label: 'Motley Fool Canada' },
  { name: 'GlobeNewswire Marketing', label: 'GlobeNewswire Marketing' },
  { name: 'NewMediaWire', label: 'NewMediaWire' },
  { name: 'Fundamental Research Corp', label: 'Fundamental Research Corp' },
  { name: 'NetworkNewsWire', label: 'NetworkNewsWire' },
  { name: 'Seeking Alpha', label: 'Seeking Alpha' },
  { name: 'Pot Stock News', label: 'Pot Stock News' },
  { name: 'BTV Bulletin', label: 'BTV Bulletin' },
  { name: 'MarketNewsUpdates', label: 'MarketNewsUpdates' },
  { name: 'Fool Canada', label: 'Fool Canada' },
  { name: 'MoneyShow.com', label: 'MoneyShow.com' },
  { name: 'Stock Traders Daily', label: 'Stock Traders Daily' },
  { name: 'CannabisNewsWire', label: 'CannabisNewsWire' },
  { name: 'StreetSignals', label: 'StreetSignals' },
  { name: 'CryptoCurrencyNews', label: 'CryptoCurrencyNews' },
  { name: 'MicroSmallCap', label: 'MicroSmallCap' },
  { name: 'InvestorBrandNetwork', label: 'InvestorBrandNetwork' },
  { name: 'Financial News Media', label: 'Financial News Media' },
  { name: 'Stock Market Manager', label: 'Stock Market Manager' },
  { name: 'FinancialBuzz.com', label: 'FinancialBuzz.com' },
  { name: 'Markets & Headlines', label: 'Markets & Headlines' },
  { name: 'Investing News Network', label: 'Investing News Network' },
  { name: 'StockMarket.com', label: 'StockMarket.com' },
  { name: 'StockStreetNews', label: 'StockStreetNews' },
  { name: 'PennyStocks', label: 'PennyStocks' },
  { name: 'WallStreet PR', label: 'WallStreet PR' },
  { name: 'Channelchek', label: 'Channelchek' },
  { name: 'MarijuanaStocks.com', label: 'MarijuanaStocks.com' },
  { name: 'Contrarian Outlook', label: 'Contrarian Outlook' },
  { name: 'IAM Newswire', label: 'IAM Newswire' },
  { name: 'Green Market Report', label: 'Green Market Report' },
  { name: 'InvestorIdeas.com', label: 'InvestorIdeas.com' },
  { name: 'StockNews.com', label: 'StockNews.com' },
  { name: 'GoldStocks.com', label: 'GoldStocks.com' },
  { name: 'IRW Press', label: 'IRW Press' },
  { name: 'QuoteMedia Research SEC Filings', label: 'QuoteMedia Research SEC Filings' },
  { name: 'InvestorPlace', label: 'InvestorPlace' },
  { name: 'Actusnews Wire', label: 'Actusnews Wire' },
  { name: 'Midas Letter News', label: 'Midas Letter News' },
  { name: 'Market Buzz', label: 'Market Buzz' },
  { name: 'Invezz', label: 'Invezz' },
  { name: 'Wall Street PR News', label: 'Wall Street PR News' },
  { name: 'StockNewsNow', label: 'StockNewsNow' },
  { name: 'FSCwire', label: 'FSCwire' },
  { name: 'y%wire', label: 'y%wire' },
  { name: 'PressReach.com', label: 'PressReach.com' },
  { name: 'QuoteMediaNews 52Wk Hi-Lo', label: 'QuoteMediaNews 52Wk Hi-Lo' },
  //
  { name: "24/7 Market News", label: "24/7 Market News" },
  { name: "Canadian Earnings Reports", label: "Canadian Earnings Reports" },
  { name: "AllPennyStocks.com", label: "AllPennyStocks.com" },
  { name: "EZ Newswire", label: "EZ Newswire" },
  { name: "Evertise Digital", label: "Evertise Digital" },
  { name: "KlickAnalytics", label: "KlickAnalytics" },
  { name: "Markets and Headlines", label: "Markets and Headlines" },
  { name: "QM Canadian Market Movers", label: "QM Canadian Market Movers" },
  { name: "QM US Market Movers News", label: "QM US Market Movers News" },
  { name: "US Earnings Reports", label: "US Earnings Reports" },
  { name: "USA News Group", label: "USA News Group" },
  { name: "iCrowdNewswire", label: "iCrowdNewswire" },
];


export const topicsList = [
  { name: 'ACCHTHIN', label: 'Accident & Health Insurance' },
  { name: 'ADAGENCY', label: 'Advertising Agencies - Sector' },
  { name: 'ADVMKTPR', label: 'Advertising, Marketing and PR' },
  { name: 'AERODEFS', label: 'Aerospace and Defense' },
  { name: 'AERODFMD', label: 'Aerospace/Defense - Major Diversified' },
  { name: 'AERODFPS', label: 'Aerospace/Defense Products & Services' },
  { name: 'AGR10101', label: 'Agriculture - Sector' },
  { name: 'ALTENERG', label: 'Alternate Energy' },
  { name: 'AMEX0001', label: 'NYSE American Exchange' },
  { name: 'ANTITRST', label: 'Antitrust' },
  { name: 'APPLIANC', label: 'Appliances' },
  { name: 'ASIAPACI', label: 'Asia Pacific' },
  { name: 'ASSMNGMT', label: 'Asset Management' },
  { name: 'AUTOMTVE', label: 'Automotive' },
  { name: 'BANKFINA', label: 'Banking and Finance' },
  { name: 'BANKING9', label: 'Banking' },
  { name: 'BASICMAT', label: 'Basic Materials - Sector' },
  { name: 'BEVBREWS', label: 'Beverages - Brewers' },
  { name: 'BEVSOFTD', label: 'Beverages - Soft Drinks' },
  { name: 'BEVWINDS', label: 'Beverages - Wineries & Distillers' },
  { name: 'BIGDATA', label: 'Big Data' },
  { name: 'BIOTCHNO', label: 'Biotechnology' },
  { name: 'BODSH', label: 'BoD/SH' },
  { name: 'BONDS001', label: 'Bonds' },
  { name: 'BREAKING', label: 'Breaking News and Urgent Updates' },
  { name: 'BROADCRD', label: 'Broadcasting - Radio' },
  { name: 'BROADCTV', label: 'Broadcasting - TV' },
  { name: 'BUSINESS', label: 'Economy, Business, and Finance' },
  { name: 'BUYBACK1', label: 'Stock Buyback' },
  { name: 'CAPITALG', label: 'Capital Gains' },
  { name: 'CASINENT', label: 'Casinos - Entertainment' },
  { name: 'CASINTRA', label: 'Casinos - Travel' },
  { name: 'CELEBRTY', label: 'Celebrity News and Gossip' },
  { name: 'CHEMICLS', label: 'Chemicals' },
  { name: 'CHICMERC', label: 'Chicago Mercantile Exchange' },
  { name: 'CIGARETT', label: 'Cigarettes' },
  { name: 'COAL0001', label: 'Coal News' },
  { name: 'COM308', label: 'Communication Services - Sector' },
  { name: 'COMMODIT', label: 'Commodities' },
  { name: 'COMPHARD', label: 'Computer Hardware' },
  { name: 'COMPSWSR', label: 'Computer Software' },
  { name: 'COMPUTER', label: 'Computer' },
  { name: 'CON102', label: 'Consumer Cyclical - Sector' },
  { name: 'CON205', label: 'Consumer Defensive - Sector' },
  { name: 'CONFEREN', label: 'Conference Calls, Web Events' },
  { name: 'CONSCRED', label: 'Consumer Credit' },
  { name: 'CONSGOOD', label: 'Consumer Goods' },
  { name: 'CONSUMER', label: 'Consumer Affairs' },
  { name: 'CPI00001', label: 'Consumer Price Index' },
  { name: 'CRUDEOIL', label: 'Crude Oil News' },
  { name: 'CRYPTOC', label: 'Cryptocurrency' },
  { name: 'DATAWARE', label: 'Data Warehousing' },
  { name: 'DIVIDEND', label: 'Cash Or Stock Dividend' },
  { name: 'DRUGS999', label: 'Pharmaceutical Companies' },
  { name: 'EARNINGS', label: 'Earnings' },
  { name: 'ECONOMY1', label: 'Economy' },
  { name: 'EDUCATIN', label: 'Education' },
  { name: 'ELE31165130', label: 'Electronic Gaming and Multimedia Sector' },
  { name: 'ELECTION', label: 'Election' },
  { name: 'ELECTRON', label: 'Electronics' },
  { name: 'ENERGY01', label: 'Energy' },
  { name: 'ENTRTAIN', label: 'Entertainment' },
  { name: 'EUROPE01', label: 'Europe' },
  { name: 'EUROUNIO', label: 'European Union' },
  { name: 'FASHION01', label: 'Fashion' },
  { name: 'FDA00001', label: 'FDA' },
  { name: 'FEDERAL', label: 'Federal' },
  { name: 'FEDRESRV', label: 'Federal Reserve' },
  { name: 'FINANCE1', label: 'Finance' },
  { name: 'FINANCIL', label: 'Financial' },
  { name: 'FINASERV', label: 'Financial and Business Services/Products' },
  { name: 'FINSERVI', label: 'Financial and Business Services Sector' },
  { name: 'FOODSBEV', label: 'Food and Beverage' },
  { name: 'FORESTPR', label: 'Forest Products' },
  { name: 'FORNEXCH', label: 'Foreign Exchange (FOREX)' },
  { name: 'FUTURES1', label: 'Futures Trading' },
  { name: 'GAMEMULT', label: 'Games and Multimedia' },
  { name: 'GAMENEWS', label: 'Game News' },
  { name: 'GAMING00', label: 'Video and Online Gaming' },
  { name: 'HEA31165131', label: 'Health Information Services' },
  { name: 'HISTORY0', label: 'History' },
  { name: 'HLTHCARE', label: 'Healthcare' },
  { name: 'HLTHSERV', label: 'Health Services' },
  { name: 'HOMEFUFX', label: 'Furnishings, Fixtures & Appliances - Sector' },
  { name: 'HOSPITAL', label: 'Hospitals' },
  { name: 'HUMANRES', label: 'Human Resources/Labor' },
  { name: 'IMF00001', label: 'IMF' },
  { name: 'IND310', label: 'Industrials - Sector' },
  { name: 'INDUSGDS', label: 'Industrial Goods' },
  { name: 'INSIDRTR', label: 'Insider Trading' },
  { name: 'INSUBROK', label: 'Insurance Brokers - Sector' },
  { name: 'INTHPINK', label: 'OTC Markets Exchange' },
  { name: 'INTLTRAD', label: 'International Trade' },
  { name: 'INTRNET9', label: 'Internet' },
  { name: 'INTRSTRA', label: 'Interest Rates' },
  { name: 'INVESTOP', label: 'Investment Opinion' },
  { name: 'IPONEWS1', label: 'IPO\'s' },
  { name: 'LEGAL000', label: 'Legal News' },
  { name: 'LEISURE9', label: 'Leisure' },
  { name: 'LSE', label: 'London Stock Exchange' },
  { name: 'MANUFACT', label: 'Manufacturing' },
  { name: 'MANUFCTU', label: 'Manufacturing and Engineering' },
  { name: 'MARKCOMM', label: 'Market News' },
  { name: 'MARKECON', label: 'Market and Economy' },
  { name: 'MATERCON', label: 'Materials and Construction' },
  { name: 'MEDIA999', label: 'Media' },
  { name: 'MEDICDEV', label: 'Medical Devices' },
  { name: 'MEDMARIJ', label: 'Medical Marijuana' },
  { name: 'MEETINGS', label: 'Meetings' },
  { name: 'MERGERAC', label: 'Mergers & Acquisitions' },
  { name: 'METALMIN', label: 'Metal and Mineral' },
  { name: 'MINING02', label: 'Mining 2' },
  { name: 'MORTGAGE', label: 'Mortgage' },
  { name: 'MUTUALFD', label: 'Mutual Funds' },
  { name: 'NASDAQ01', label: 'Nasdaq' },
  { name: 'NATGAS02', label: 'Natural Gas Companies & Products' },
  { name: 'NATIONAL', label: 'National News' },
  { name: 'NATURALG', label: 'Natural Gas News & Research' },
  { name: 'NATURALR', label: 'Natural Resources' },
  { name: 'NEWYORK1', label: 'New York' },
  { name: 'NONCOMPANY', label: 'Non-Company' },
  { name: 'NOUS', label: 'No US Distribution' },
  { name: 'NYSE0001', label: 'NYSE' },
  { name: 'OILENERG', label: 'Oil & Energy' },
  { name: 'OPEC0001', label: 'OPEC News' },
  { name: 'PHA20533080', label: 'Pharmaceutical Retailers' },
  { name: 'PHARMACE', label: 'Pharmaceutical News' },
  { name: 'POLITICS', label: 'Politics' },
  { name: 'PRHYPE', label: 'Press Release Hype' },
  { name: 'PRODPRIC', label: 'Producer Price Index' },
  { name: 'PUB10215', label: 'Publishing - Sector' },
  { name: 'RAILROAD', label: 'Railroad' },
  { name: 'RANT0000', label: 'Rantings' },
  { name: 'RATINGS1', label: 'Ratings' },
  { name: 'REALESTT', label: 'Real Estate' },
  { name: 'REI10428', label: 'REITs' },
  { name: 'REMOVE', label: 'Removed Stories' },
  { name: 'RETAIL99', label: 'Wholesale and Retail' },
  { name: 'SCIENCE0', label: 'Science' },
  { name: 'SOCIALIS', label: 'Social Issues' },
  { name: 'SOFTWARE', label: 'Software' },
  { name: 'SOLARPOW', label: 'Solar Power' },
  { name: 'SPAM', label: 'SPAM' },
  { name: 'STATEPOL', label: 'State Politics' },
  { name: 'STE10107', label: 'Steel - Sector' },
  { name: 'STKSPLIT', label: 'Stock Splits' },
  { name: 'TECHNOLO', label: 'Computing and Information Technology - Sector' },
  { name: 'TELECOMU', label: 'Telecommunications' },
  { name: 'TOBACOO9', label: 'Tobacco' },
  { name: 'TRANSPRT', label: 'Transportation' },
  { name: 'TSXTSX01', label: 'TSX and TSXV Exchanges' },
  { name: 'URANIUM1', label: 'Uranium News' },
  { name: 'UTILITIE', label: 'Utilities' },
  { name: 'VENTUREC', label: 'Venture Capital' },
  { name: 'WORLDNEW', label: 'World News' }
];


export const pressReleaseList = sourcesList.filter(x => x.name.toLowerCase().includes('wire'));
export const otherNewsList = sourcesList.filter(x => !x.name.toLowerCase().includes('wire'));


export const secCategoriesList = [
  { name: 'other', label: 'Other' },
  { name: 'news', label: 'News' },
  { name: 'ownership', label: 'Ownership' },
  { name: 'earnings', label: 'Earnings' },
  { name: 'proxy', label: 'Proxy' },
  { name: 'dilution', label: 'Dilution' }
];


export const SOURCE_COLUMNS = {
  NEWS_TOPICS: {
    id: 'NEWS_TOPICS',
    data: topicsList
  },
  NEWS_SOURCES: {
    id: 'NEWS_SOURCES',
    data: sourcesList
  },
  SEC_CATEGORIES: {
    id: 'SEC_CATEGORIES',
    data: secCategoriesList
  },
}

// ENUMS to make deserialization a bit less error-prone. Check filterStateFunctions.js for use
export const CB_ITEM_NAMES = {
  NEWS_ROOT: 'NEWS_ROOT',
  SOURCES: 'SOURCES',
  TOPICS_LABEL: 'TOPICS_LABEL',
  TOPICS: 'TOPICS',
  PRESS_RELEASE: 'PRESS_RELEASE',
  OTHER_NEWS: 'OTHER_NEWS',

  SEC_ROOT: 'SEC_ROOT',
  SEC_CATEGORIES: 'SEC_CATEGORIES'
}


export const FLAGS = {
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  ALL: 'ALL',
  NONE: 'NONE'
};


export const newsCategoriesChecklistItems = [
  {
    label: 'News',
    name: CB_ITEM_NAMES.NEWS_ROOT,
    parent: null,
    checkbox: true,
    childType: CHILD_TYPES.INDENTED,
  },
  {
    label: 'Sources',
    name: CB_ITEM_NAMES.SOURCES,
    checkbox: false,
    childType: CHILD_TYPES.NESTED,
    parent: CB_ITEM_NAMES.NEWS_ROOT
  },
  {
    label: 'Press Releases',
    name: CB_ITEM_NAMES.PRESS_RELEASE,
    checkbox: true,
    childType: CHILD_TYPES.INDENTED,
    parent: CB_ITEM_NAMES.SOURCES
  },
  ...pressReleaseList.map(x => ({
    name: x.name,
    label: x.label,
    checkbox: true,
    source: SOURCE_COLUMNS.NEWS_SOURCES.id,
    parent: CB_ITEM_NAMES.PRESS_RELEASE,
  })),
  {
    label: 'Other News',
    name: CB_ITEM_NAMES.OTHER_NEWS,
    checkbox: true,
    childType: CHILD_TYPES.INDENTED,
    parent: CB_ITEM_NAMES.SOURCES
  },
  ...otherNewsList.map(x => ({
    name: x.name,
    label: x.label,
    checkbox: true,
    source: SOURCE_COLUMNS.NEWS_SOURCES.id,
    parent: CB_ITEM_NAMES.OTHER_NEWS
  })),
  {
    label: 'Topics',
    name: CB_ITEM_NAMES.TOPICS_LABEL,
    checkbox: false,
    childType: CHILD_TYPES.NESTED,
    parent: CB_ITEM_NAMES.NEWS_ROOT
  },
  {
    label: 'Select All',
    name: CB_ITEM_NAMES.TOPICS,
    checkbox: true,
    childType: CHILD_TYPES.INDENTED,
    parent: CB_ITEM_NAMES.TOPICS_LABEL,
    classes: 'checkbox-border-bottom'
  },
  ...topicsList.map(x => ({
    name: x.name,
    label: x.label,
    checkbox: true,
    source: SOURCE_COLUMNS.NEWS_TOPICS.id,
    parent: CB_ITEM_NAMES.TOPICS
  })),
  {
    label: 'SEC Filings',
    name: CB_ITEM_NAMES.SEC_ROOT,
    checkbox: true,
    childType: CHILD_TYPES.INDENTED,
    parent: null,
  },
  {
    label: 'Categories',
    name: CB_ITEM_NAMES.SEC_CATEGORIES,
    checkbox: false,
    childType: CHILD_TYPES.NESTED,
    parent: CB_ITEM_NAMES.SEC_ROOT
  },
  ...secCategoriesList.map(x => ({
    name: x.name,
    label: x.label,
    checkbox: true,
    source: SOURCE_COLUMNS.SEC_CATEGORIES.id,
    parent: CB_ITEM_NAMES.SEC_CATEGORIES
  }))
];




